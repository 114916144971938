import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/buster-sword.png";
import projImg2 from "../assets/img/masamune.png";
import projImg3 from "../assets/img/flower-peddler.png";
import projImg4 from "../assets/img/baby-kweh.png";
import projImg5 from "../assets/img/jef-wilch.png";
import projImg6 from "../assets/img/pyro-james.png";
import projImg7 from "../assets/img/ipatticus.png";
import projImg8 from "../assets/img/did-i-do-maple.png";
import projImg9 from "../assets/img/dino-chaps.png";
import projImg10 from "../assets/img/cowabunga.png";
import projImg11 from "../assets/img/wafflesaurus.png";
import projImg12 from "../assets/img/vacation-shirts.png";
import projImg13 from "../assets/img/im-already.png";
import projImg14 from "../assets/img/tavern-brawl.png";
import projImg15 from "../assets/img/fbt-straw.png";
import projImg16 from "../assets/img/tmnt-leo.png";
import projImg17 from "../assets/img/tmnt-don.png";
import projImg18 from "../assets/img/hey-hefe.png";
import projImg19 from "../assets/img/gold-scarab.png";
import projImg20 from "../assets/img/7days.png";
import projImg21 from "../assets/img/6days.png";
import projImg22 from "../assets/img/fruit-madness.png";
import projImg23 from "../assets/img/falcon.png";
import projImg24 from "../assets/img/jackal.png";
import projImg25 from "../assets/img/pixel.png";
import projImg26 from "../assets/img/smarty-pink.png";
import projImg27 from "../assets/img/sour-pitch.png";
import projImg28 from "../assets/img/amakara.png";
import projImg29 from "../assets/img/bloodstained.png";
import projImg30 from "../assets/img/sabotage.png";
import projImg31 from "../assets/img/peach-cobbler.png";
import projImg32 from "../assets/img/puffpuff.png";
import projImg33 from "../assets/img/high-roller.png";
import projImg34 from "../assets/img/darkness.png";
import projImg35 from "../assets/img/ive-held.png";
import projImg36 from "../assets/img/unbirthday.png";
import projImg37 from "../assets/img/hop-reaper.png";
import projImg38 from "../assets/img/zentangle-whale.png";
import projImg39 from "../assets/img/chameleon.png";
import projImg40 from "../assets/img/hopsa.png";
import projImg41 from "../assets/img/galaxyHops.png";
import projImg42 from "../assets/img/angler.png";
import projImg43 from "../assets/img/hoptopus.png";
import projImg44 from "../assets/img/racoon-beer.png";
import projImg45 from "../assets/img/frenchy-beer.png";
import projImg46 from "../assets/img/neon-skeleton.png";
import projImg47 from "../assets/img/mario-head.png";
import projImg48 from "../assets/img/hop-heart.png";
import projImg49 from "../assets/img/3zombies.png";
import projImg50 from "../assets/img/viking.png";
import projImg51 from "../assets/img/cute-harry.png";
import projImg52 from "../assets/img/mj-flamingo.png";
import projImg53 from "../assets/img/IT-lineart.png";
import projImg54 from "../assets/img/rabbit.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects1 = [
    {
      title: "Buster Sword",
      description: "Ellipsis Brewing",
      imgUrl: projImg1,
    },
    {
      title: "Masamune",
      description: "Ellipsis Brewing",
      imgUrl: projImg2,
    },
    {
      title: "Flower Peddler",
      description: "Ellipsis Brewing",
      imgUrl: projImg3,
    },
    {
      title: "Baby Kweh",
      description: "Ellipsis Brewing",
      imgUrl: projImg4,
    },
    {
      title: "Jef Wilch",
      description: "Ellipsis Brewing",
      imgUrl: projImg5,
    },
    {
      title: "Pyro James",
      description: "Ellipsis Brewing",
      imgUrl: projImg6,
    },
  ];

  const projects2 = [
    {
      title: "I P Atticus",
      description: "Ellipsis Brewing",
      imgUrl: projImg7,
    },
    {
      title: "Did I Do Thattt Maple",
      description: "Ellipsis Brewing",
      imgUrl: projImg8,
    },
    {
      title: "Dino Chaps",
      description: "Ellipsis Brewing",
      imgUrl: projImg9,
    },
    {
      title: "Cowabunga",
      description: "Ellipsis Brewing",
      imgUrl: projImg10,
    },
    {
      title: "Waffle Saurus",
      description: "Ellipsis Brewing",
      imgUrl: projImg11,
    },
    {
      title: "Vacation Shirts",
      description: "Ellipsis Brewing",
      imgUrl: projImg12,
    },
  ];

  const projects3 = [
    {
      title: "I'm Already Pulled Over",
      description: "Ellipsis Brewing",
      imgUrl: projImg13,
    },
    {
      title: "Tavern Brawl",
      description: "Ellipsis Brewing",
      imgUrl: projImg14,
    },
    {
      title: "Fruit By The...",
      description: "Ellipsis Brewing",
      imgUrl: projImg15,
    },
    {
      title: "Katanas",
      description: "Ellipsis Brewing",
      imgUrl: projImg16,
    },
    {
      title: "Bow Staff",
      description: "Ellipsis Brewing",
      imgUrl: projImg17,
    },
    {
      title: "Hey Hefe",
      description: "Ellipsis Brewing",
      imgUrl: projImg18,
    },
  ];

  const projects4 = [
    {
      title: "Gold Scarab",
      description: "Ingenious Brewing",
      imgUrl: projImg19,
    },
    {
      title: "7 days of Stouts",
      description: "Ellipsis Brewing",
      imgUrl: projImg20,
    },
    {
      title: "6 days of Sours",
      description: "Ellipsis Brewing",
      imgUrl: projImg21,
    },
    {
      title: "Fruit Madness",
      description: "Ingenious Brewing",
      imgUrl: projImg22,
    },
    {
      title: "The Falcon",
      description: "Ingenious Brewing",
      imgUrl: projImg23,
    },
    {
      title: "The Jackal",
      description: "Ingenious Brewing",
      imgUrl: projImg24,
    },
  ];

  const projects5 = [
    {
      title: "Pixel",
      description: "Ingenious Brewing",
      imgUrl: projImg25,
    },
    {
      title: "Smarty Pink Tropics",
      description: "Ingenious Brewing",
      imgUrl: projImg26,
    },
    {
      title: "Sour Pitch Kids",
      description: "Ingenious Brewing",
      imgUrl: projImg27,
    },
    {
      title: "Amakara",
      description: "Nectareous Mead",
      imgUrl: projImg28,
    },
    {
      title: "Bloodstaind",
      description: "Nectareous Mead",
      imgUrl: projImg29,
    },
    {
      title: "Sabotage",
      description: "Nectareous Mead",
      imgUrl: projImg30,
    },
  ];

  const projects6 = [
    {
      title: "Peach Cobbler",
      description: "Nectareous Mead",
      imgUrl: projImg31,
    },
    {
      title: "Puff Puff Give",
      description: "Nectareous Mead",
      imgUrl: projImg32,
    },
    {
      title: "High Roller",
      description: "Nectareous Mead",
      imgUrl: projImg33,
    },
    {
      title: "Darkness",
      description: "Nectareous Mead",
      imgUrl: projImg34,
    },
    {
      title: "I've held deaths hand",
      description: "Nectareous Mead",
      imgUrl: projImg35,
    },
    {
      title: "Unbirthday Party",
      description: "Nectareous Mead",
      imgUrl: projImg36,
    },
  ];

    const projects7 = [
    {
      title: "Hop Reaper",
      description: "Hop Killers",
      imgUrl: projImg37,
    },
    {
      title: "Zentangle Whale",
      description: "Hop Killers",
      imgUrl: projImg38,
    },
    {
      title: "Trippy Chameleon",
      description: "Hop Killers",
      imgUrl: projImg39,
    },
    {
      title: "Hopsa",
      description: "Hop Killers",
      imgUrl: projImg40,
    },
    {
      title: "Galaxy Hops",
      description: "Hop Killers",
      imgUrl: projImg41,
    },
    {
      title: "Angler Fish",
      description: "Hop Killers",
      imgUrl: projImg42,
    },
  ];

  const projects8 = [
    {
      title: "Hoptopus",
      description: "Hop Killers",
      imgUrl: projImg43,
    },
    {
      title: "Rabid Racoon",
      description: "Hop Killers",
      imgUrl: projImg44,
    },
    {
      title: "BA Stouts",
      description: "Hop Killers",
      imgUrl: projImg45,
    },
    {
      title: "Neon Experiment",
      description: "Hop Killers",
      imgUrl: projImg46,
    },
    {
      title: "Mario",
      description: "Hop Killers",
      imgUrl: projImg47,
    },
    {
      title: "Hop Heart",
      description: "Hop Killers",
      imgUrl: projImg48,
    },
  ];

  const projects9 = [
    {
      title: "Zombie Crew",
      description: "Hop Killers",
      imgUrl: projImg49,
    },
    {
      title: "Viking",
      description: "Hop Killers",
      imgUrl: projImg50,
    },
    {
      title: "Harry Potter",
      description: "Hop Killers",
      imgUrl: projImg51,
    },
    {
      title: "Flamingo",
      description: "Hop Killers",
      imgUrl: projImg52,
    },
    {
      title: "IT Lineart experiment",
      description: "Hop Killers",
      imgUrl: projImg53,
    },
    {
      title: "Rabbit",
      description: "Hop Killers",
      imgUrl: projImg54,
    },
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>I have had the pleasure of working on a diverse range of projects in the past, from Merch Design to Labels, and everything in between. Here you can find a small selection of past projects i have done for various breweries, Nectareous Mead which I owned, and some of my Merch Designs</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Labels</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">More Labels</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Other Artwork</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects2.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects3.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          projects4.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects5.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects6.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          projects7.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects8.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <Row>
                        {
                          projects9.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}